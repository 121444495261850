.container{
    padding: .7em;
    display: flex;
}
.logo{
    flex: 1;
    display: flex;
    align-items: center;
}
.logo>Img{
    height: 100px;
    width: 100px;
}
.right{
    gap: 32px;
    justify-content: center;
    align-items: center;
    flex: 1;
    display: flex;
}
.menu{
    text-transform: capitalize;
    display: flex;
    gap: 32px;
}
.menu>li{
    list-style: none;
}
.menu>li:hover{
    cursor: pointer;
    color: chocolate;
}
.search{
    outline: none;
    border: none;
    padding: 7px;
    border-radius: 12px;
    height: 20%;
    width: 79px;
}
.cart{
    width: 22px;
    height: 22px;
    cursor: pointer;
}