.footer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid: 32%;
    gap: 10px;
    background: rgba(255, 255, 255, 0.199);
    padding: 20px 0px;
}
.section1{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.section1>span{
    text-transform: uppercase;
}
.section1>img{
    width: 100px;
}
.section2{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
}
.contact{
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 500;
}
.contact_in{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.section3{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.section3>:first-child{
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
}