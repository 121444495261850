.Virtual{
    display: flex;
    margin: 20px 0px;
    justify-content: space-around;
    align-items:center;
}
.V_left{
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 7px;
    text-transform: uppercase;
    width: 210px;
    height: 210px;
}
.V_left>:nth-child(1){
    font-size: 29px;
    font-weight: 600;
    margin: 17px 0px;
}
.V_left>:nth-child(2){
    font-size: 20px;
    font-weight: 500;
}
.V_left>:nth-child(3){
    font-size: large;
    font-weight: 700;
    text-transform: capitalize;
}
.V_left>img{
    display: block;
    width: 100px;
    position: absolute;
    bottom: 10px;
    left: -9px;
}
.img_wrapper{
    width: 400px;
}