.container{
    margin: 140px 0px;
    padding: 0px 30px;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
}
.container>:nth-child(){
    position: absolute;
    left: 30%;
    top: -20px;
    width: 100px;
    height: 70px;
}
.products{
    display: grid;
    width: 90%;
    grid-template-columns: 25% auto;
}
.menu_items{
    text-transform: uppercase;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-size: 17px;
    font-weight: 600;
}
.menu_items>li:hover{
    cursor: pointer;
    color: chocolate;
}
.list{
    display: grid;
    grid-template-columns: repeat(auto-fill ,minmax(200px, 1fr));
    height: 280px;
    grid-gap: 20px;
    overflow-y: scroll;
    justify-content: space-between;
}
.product>img{
    height: 170px;
    width: 80px;
}
.product{
    margin: 2px;
    display: flex;
    width: 176px;
    height: 200px;
    overflow: hidden;
    background: white;
    position: relative;
    border-radius: 10px;
    padding: 10px;
    justify-content: center;
}