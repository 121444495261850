.container{
    padding: 0em 1em;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
}
.mid{
    display: flex;
    align-items: baseline;
    justify-content: center;
    position: relative;
}

.mid>img{
    height: 350px;
    width: 350px;
    position: absolute;
    bottom: 0;
}
.blue_circle{
    width: 370px;
    height: 370px;
    border-radius: 50%;
    z-index: -99;
    position: absolute;
    bottom: 0;
    background: linear-gradient(135.74deg , #0bb5ff 14.09% , #6dd3fe 83.81% );
}
.cart{
    display: flex;
    position: absolute;
    bottom: 15%;
    right: 1%;
    gap: 10px;
    align-items: center;
}
.cart>svg{
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 50%;
    padding: 10px;
    border: 1px solid black;

}
.C_sides{
    display: grid;
    grid-template-rows: 1fr 1fr;
}
.signup{
    display: flex;
    align-items: center;
    flex-direction: row;
    background: white;
    border-radius: 10px;
    padding: 7px;
    box-shadow: var(--shadow1);
}
.signup>div>svg{
    border-radius: 50%;
    border: 1px solid #6dd3fe;
    padding: 3px;
    height: 17px;
    width: 17px;
}
.signup>:first-child{
    width: 110px;
}
.text1{
    text-transform: uppercase;
    font-size: 40px;
    width:min-content;
    font-weight: 700;
    margin-bottom: 20px;
}
.text2{
    display: flex;
    flex-direction: column;
    width:min-content;
}
.text2>:first-child{
    font-weight: 600;
    font-size: 30px;
}
.text2>:nth-child(2){
    display: block;
    word-wrap:normal;
}
.traffic{
    text-align: right;
    display: flex;
    flex-direction: column;
}
.traffic>:first-child{
    font-size: 40px;
    font-weight: 700;
}
.customers{
    display: flex;
    flex-direction: column;
    text-align: right;
}
.customers>:first-child{
    font-size: 40px;
    font-weight: 700;
}