.testimonials{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 20px;
}
.wrapper{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    width: 100%;
    align-items: flex-end;
}
.container{
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
}
.wrapper>img{
    width: 300px;
    justify-self: center;
}
.wrapper2{
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-self: center;
    text-align: center;
}
.container>:nth-child(1){
    font-size: 27px;
    font-weight: 600;
    text-transform: uppercase;
}
.wrapper2>:nth-child(1){
    font-size: 27px;
    font-weight: 600;
    text-transform: uppercase;
}
.carousel{
    width: 100%;
    margin: 100px 0px;
}
.tCarousel{
    justify-content: space-between;
    padding: 40px;
}
.testimonial{
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.267);
    padding: 20px;
    align-items: center;
    justify-content: center;
    gap: 17px;
    position: relative;
}
.testimonial>img{
    position: absolute ;
    width: 40px ;
    top: -20px ;
    left: 45%;
}
.testimonial>span{
    margin-top: 19px;
    font-size: 17px;
    font-weight: 500;

}
.testimonial>hr{
    height: 1px;
    width: 80%;
}
