.slider_container{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 70px 0px;
}
.slider_container .swiper{
    width: 80%;
    height: 130px;
    position: unset;
}
.slider_container .swiper-slide{
    justify-content: space-between;
    background: white;
    display: flex;
    border-radius: 7px;
    padding: 10px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}
.slider_container .swiper-button-prev{
    left: 34px;
}
.slider_container .swiper-button-next{
    right: 34px;
}
.swiper-pagination{
    display: none;
}
.slide_name{
    display: flex;
    flex-direction: column;
    margin-bottom: 7px;
}
.left_slide>span{
    font-size: 17px;
    font-weight: 600;
}
.slide_name>:first-child{
    font-size: 20px;
    font-weight: 600;
}